<template>
  <div class="events-view">
    <div class="events-view__wrapper">
      <Section limiter>
        <BreadCrumbs :class="$mobile ? 'm-t-40' : ''"
                     :params="{navigation, color: 'rgba(0, 0, 0, .5)'}"/>
        <h1 class="events-view__title">{{ $t('events_view.playbill') }}</h1>
<!--        <Tabs>-->
<!--          <router-link to="/events"-->
<!--                       :class="$route.path === '/events' ? 'tabs__active' : ''"-->
<!--                       class="tabs__tab">События-->
<!--          </router-link>-->
          <!--          <router-link to="/news"-->
          <!--                       :class="$route.path === '/news' ? 'tabs__active' : ''"-->
          <!--                       class="tabs__tab">Новости-->
          <!--          </router-link>-->
          <!--          <router-link to="/stories"-->
          <!--                       :class="$route.path === '/stories' ? 'tabs__active' : ''"-->
          <!--                       class="tabs__tab">Сюжеты-->
          <!--          </router-link>-->
<!--        </Tabs>-->
        <Row class="thematic-slider__filter">
          <DateTimePicker :time-picker="false"
                          auto-close
                          format="DD MMMM"
                          locale="ru"
                          :value="date"
                          @input="setDate"
                          v-on:close="changeColor"
                          v-on:open="changeColor">
            <template slot="formatted-datetime">
              <div class="news-view__tag news-view__tag-black">
                {{$moment(date).format('DD MMMM')}}
                <div @click.stop="removeDate">
                  <Icon class="news-view__tag-icon news-view__tag-close"
                        viewport="0 0 20 20"
                        xlink="close"/>
                </div>
              </div>
            </template>
            <template slot="choose-date">
              <div class="news-view__tag">{{ $t('events_view.date') }}
                <Icon :rotate="180"
                      class="news-view__tag-icon"
                      viewport="0 0 20 20"
                      xlink="arrow-navigation"/>
              </div>
            </template>
            <template slot="months-prev">
              <Icon :rotate="-90"
                    viewport="0 0 20 20"
                    xlink="arrow-navigation"/>
            </template>
            <template slot="months-next">
              <Icon :rotate="90"
                    viewport="0 0 20 20"
                    xlink="arrow-navigation"/>
            </template>
            <template slot="decades-prev">
              <Icon :rotate="-90"
                    viewport="0 0 20 20"
                    xlink="arrow-navigation"/>
            </template>
            <template slot="decades-next">
              <Icon :rotate="90"
                    viewport="0 0 20 20"
                    xlink="arrow-navigation"/>
            </template>
            <template slot="years-prev">
              <Icon :rotate="-90"
                    viewport="0 0 20 20"
                    xlink="arrow-navigation"/>
            </template>
            <template slot="years-next">
              <Icon :rotate="90"
                    viewport="0 0 20 20"
                    xlink="arrow-navigation"/>
            </template>
          </DateTimePicker>
          <div class="news-view__tag"
               v-if="$laptop"
               v-on:click="today">{{ $t('events_view.today') }}
          </div>
          <div class="news-view__tag"
               v-if="$laptop"
               v-on:click="tomorrow">{{ $t('events_view.tomorrow') }}
          </div>
        </Row>
        <Row class="-m-15"
             wrap>
          <EventCard v-for="event in $events.data"
                     class="grid-md-4 grid-lg-4 events-view__event"
                     :key="event.id"
                     :params="event"/>
        </Row>
        <Row v-if="$events.links.next">
          <Button v-on:click.native="nextPage"
                  class="trips-view__more"
                  color="like-link">{{ $t('service.show_more') }}
          </Button>
        </Row>
      </Section>
      <Section limiter>
        <Share :url="`${$host}/stories`"
               :title="share.title"
               :description="share.description"/>
      </Section>
      <Section v-if="$showIfDefaultCity"
               :limiter="!$mobile">
        <FormationPortal :backgroundImage="$laptop ?
          require('@/assets/images/mobile.jpg') : ''">
          <template slot="formation-portal-title">
            <span class="formation-portal__title">{{ $t('home.app.p_1') }} <br>
              {{ $t('home.app.p_2') }}</span>
          </template>
          <component :is="$mobile ? 'Column' : 'Row'"
                     :full="$mobile"
                     :class="$mobile ? '' : '-m-15'">
            <a class="home-view__app-icon"
               :href="appStoreHref">
              <img class="icon-app"
                   :src="require('@/assets/images/svg/appstore.svg')" alt/>
            </a>
            <a class="home-view__app-icon"
               :href="googlePlayHref">
              <img class="icon-app"
                   :src="require('@/assets/images/svg/googleplay.svg')" alt/>
            </a>
          </component>
        </FormationPortal>
      </Section>
<ModifiedBillboard/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventsView',
  data() {
    return {
      dates: {
        started: this.$moment()
          .date(this.$moment()
            .date())
          .format('YYYY-MM-DD'),
        stopped: null,
      },
      date: '',
      appStoreHref: process.env.VUE_APP_DEFAULTH_MOBILE_APPLE_LINK,
      googlePlayHref: process.env.VUE_APP_DEFAULTH_MOBILE_ANDROID_LINK,
    };
  },
  methods: {
    nextPage() {
      this.$store.dispatch('GET_EVENTS_FROM_SERVER_WITH_PARAMS', {
        params: {
          count: 6,
          city_id: this.$city.id,
          page: this.$events.links.next.split('?')[1].split('=')[1],
          lang: this.$i18n.locale,
        },
      });
    },
    removeDate() {
      this.dates.started = this.$moment()
        .date(this.$moment()
          .date())
        .format('YYYY-MM-DD');
      this.dates.stopped = null;
      this.date = '';
      this.request();
    },
    changeColor() {
    },
    setDate(event) {
      this.date = event;
      this.dates.started = this.$moment(event)
        .format('YYYY-MM-DD');
      this.dates.stopped = this.$moment(event)
        .format('YYYY-MM-DD');
      this.request();
    },
    today() {
      this.dates.started = this.$moment()
        .format('YYYY-MM-DD');
      this.dates.stopped = this.$moment()
        .format('YYYY-MM-DD');
      this.date = this.$moment();
      this.request();
    },
    tomorrow() {
      this.dates.started = this.$moment()
        .date(this.$moment()
          .date() + 1)
        .format('YYYY-MM-DD');
      this.dates.stopped = this.$moment()
        .date(this.$moment()
          .date() + 1)
        .format('YYYY-MM-DD');
      this.date = this.$moment()
        .date(this.$moment()
          .date() + 1);
      this.request();
    },
    request() {
      this.$store.dispatch('GET_EVENTS_FROM_SERVER_WITH_PARAMS', {
        clear: true,
        params: {
          count: 100500,
          resolution: 'medium',
          city_id: this.$city.id,
          started_at: this.dates.started,
          stopped_at: this.dates.stopped,
          lang: this.$i18n.locale,
        },
      });
    },
  },
  computed: {
    navigation() {
      return [{
        href: '/',
        title: this.$i18n.t('menu.main'),
      }, {
        href: '/events',
        title: this.$i18n.t('menu.playbill'),
      }];
    },
  },
  created() {
    this.$store.dispatch('GET_EVENTS_FROM_SERVER_WITH_PARAMS', {
      clear: true,
      params: {
        count: 100500,
        resolution: 'medium',
        city_id: this.$city.id,
        started_at: this.dates.started,
        stopped_at: this.dates.stopped,
        lang: this.$i18n.locale,
      },
    });
  },
};
</script>
